export default [
    {
        type: "selection",
        width: 38,
        align: "center",
    },
    
    {
        title: 'Task',
        key: 'Subject',
        minWidth:100,
    },
    
	{ 
        title: 'Staff Name',
        key: 'StaffName',
        minWidth: 50,
    },

    {
        title: 'Deadline',
        key: 'deadline',
        minWidth: 80,
        sortable: true,
    }
]